export const CHAIN_ID = [1, 5, 31337];

export const GATEWAY_URL = 'https://gateway.autonolas.tech/ipfs/';

export const MORE_COLOR = {
  SECONDARY_GRAY: '#f0f0f0',
  TERTIARY_GRAY: '#C4C4C4',
};

export const SITE_URL = 'https://centaur.autonolas.network';
export const SITE_TITLE = 'Centaurs';
export const SITE_DESCRIPTION = 'Train AI bots with your community. Earn ownership and sell them on.';
export const SITE_METATAG_IMAGE = `${SITE_URL}/images/site-metadata/site-metatag.jpg`;

export const META_TAGS_INFO = {
  siteUrl: SITE_URL,
  title: SITE_TITLE,
  description: SITE_DESCRIPTION,
  image: SITE_METATAG_IMAGE,
};

export const CENTAUR_BOT_ADDRESS = 'did:key:z6MkrYzXr16B3QHrbC1LSdHwN36dtWxxj9LNuL58bEdmEkzy';

export const HASH_PREFIX = 'f01701220';
export const HASH_PREFIXES = {
  type1: HASH_PREFIX,
  type2: 'bafybei',
};

export const GNOSIS_SCAN_URL = 'https://gnosisscan.io/';

// default ID & hash when "mech" page is loaded
export const DEFAULT_MECH_CONTRACT_ADDRESS = '0xFf82123dFB52ab75C417195c5fDB87630145ae81';
export const DEFAULT_MECH_HASH = '0xf7b5a30348ac45e9cc7311715a359c4549cf6022e27681826f0f087a2de5cf91';

export const OLAS_CENTAUR_ID = '2';
