import { useState, useEffect } from 'react';
import {
  // useSelector,
  useDispatch,
} from 'react-redux';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import {
  Layout, Menu, Grid, Result, Button, Tag,
} from 'antd/lib';
import PropTypes from 'prop-types';
import { setMemoryDetails } from 'store/setup/actions';
import { getMemoryDetails } from 'common-util/api';
import Login from '../Login';

import {
  CustomLayout,
  Logo,
  RightMenu,
  LoginXsContainer,
  SupportOnlyDesktop,
} from './styles';

const INTERVAL = 10000;

const LogoSvg = dynamic(() => import('common-util/SVGs/logo'));
const AlphaLogoSvg = dynamic(() => import('common-util/SVGs/logo-alpha'));
const Footer = dynamic(() => import('./Footer'));

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;

const menuItems = [
  // { key: 'docs', label: 'Docs' },
];

const NavigationBar = ({ children }) => {
  const screens = useBreakpoint();
  const router = useRouter();
  const [selectedMenu, setSelectedMenu] = useState('homepage');
  const [isMinimized, setIsMinimized] = useState(true);
  const { pathname } = router;

  const dispatch = useDispatch();

  const getMembers = async () => {
    const { response } = await getMemoryDetails();
    dispatch(setMemoryDetails(response));
  };

  useEffect(async () => {
    getMembers();
  }, []);

  // poll details
  useEffect(() => {
    const interval = setInterval(() => {
      getMembers();
    }, INTERVAL);

    return () => {
      clearTimeout(interval);
    };
  }, []);

  // to set default menu on first render
  useEffect(() => {
    if (pathname) {
      const name = pathname.split('/')[1];
      setSelectedMenu(name || 'homepage');
    }
  }, [pathname]);

  const handleMenuItemClick = ({ key }) => {
    router.push(key === 'homepage' ? '/' : `/${key}`);
    setSelectedMenu(key);
  };

  const logo = (
    <Logo onClick={() => router.push('/')}>
      {process.env.NEXT_PUBLIC_IS_ALPHA === 'true' ? (
        <AlphaLogoSvg />
      ) : (
        <LogoSvg />
      )}
    </Logo>
  );

  if (screens.xs) {
    return (
      <CustomLayout hasSider>
        <Header>
          {logo}
          {process.env.NEXT_PUBLIC_IS_ALPHA === 'true' && (
            <Tag title="ALPHA" color="green" />
          )}
        </Header>
        <SupportOnlyDesktop>
          <Result
            status="warning"
            title="Not supported on mobile, please switch to desktop"
          />
        </SupportOnlyDesktop>
      </CustomLayout>
    );
  }

  return (
    <CustomLayout>
      <Header className="border-bottom px-24">
        {logo}

        <Menu
          theme="light"
          mode="horizontal"
          selectedKeys={[selectedMenu]}
          items={menuItems}
          onClick={handleMenuItemClick}
        />

        {process.env.NEXT_PUBLIC_IS_ALPHA === 'true' && (
          <Button
            type="link"
            className="mt-12"
            onClick={() => router.push('/my-centaur')}
          >
            My Centaur
          </Button>
        )}

        {!screens.xs && (
          <RightMenu>
            &nbsp; &nbsp; &nbsp;
            <Login />
          </RightMenu>
        )}
      </Header>

      <Content className={`site-layout ${isMinimized ? '' : 'minimize-inner-layout'}`}>
        <div className="site-layout-background">
          {!!screens.xs && (
            <LoginXsContainer>
              <Login />
            </LoginXsContainer>
          )}
          {children}
        </div>
      </Content>

      <Footer setIsMinimized={setIsMinimized} />
    </CustomLayout>
  );
};

NavigationBar.propTypes = {
  children: PropTypes.element,
};

NavigationBar.defaultProps = {
  children: null,
};

export default NavigationBar;
