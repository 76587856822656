import Web3 from 'web3';
import {
  AGENT_FACTORY_ADDRESS,
  AGENT_MECH_ABI,
  AGENT_REGISTRY_ADDRESS,
} from 'common-util/AbiAndAddresses';

export const ADDRESSES = {
  100: {
    agentRegistry: AGENT_REGISTRY_ADDRESS,
    agentFactory: AGENT_FACTORY_ADDRESS,
  },
};

export const getWeb3Details = () => {
  /**
   * web3 provider =
   * - wallect-connect provider or
   * - currentProvider by metamask or
   * - fallback to remote mainnet [remote node provider](https://web3js.readthedocs.io/en/v1.7.5/web3.html#example-remote-node-provider)
   */
  const web3 = new Web3(
    window.WEB3_PROVIDER
      || window.web3?.currentProvider
      || process.env.NEXT_PUBLIC_MAINNET_URL,
  );

  const chainId = Number(window.ethereum?.chainId || 1); // default to mainnet
  const address = ADDRESSES[chainId];
  return { web3, address, chainId };
};

export const getMechContract = () => {
  const { web3 } = getWeb3Details();
  const contract = new web3.eth.Contract(
    AGENT_MECH_ABI,
    '0xFf82123dFB52ab75C417195c5fDB87630145ae81',
  );
  return contract;
};

export const rpc = {
  1: process.env.NEXT_PUBLIC_MAINNET_URL,
  5: process.env.NEXT_PUBLIC_GOERLI_URL,
  100: process.env.NEXT_PUBLIC_GNOSIS_URL,
};
